<template lang="pug">
    div
        v-card( elevation="2" )
            v-card-title
                | Audiencias
                v-spacer
                v-text-field(
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Búscar"
                    single-line
                    hide-details
                )
            v-data-table(
                :headers="headers"
                :items="items"
                :loading="loading"
                :search="search"
            )
                template( v-slot:item.channels="{ item }" )
                    v-chip(x-small dark color="secondary" class="mr-1" v-for="name in item.channels") {{name}}

                template( v-slot:item.active_audience="{ item }" )
                    v-chip( v-if="item.active_audience == 0 && item.frequency != '-'" x-small color="red" dark ) Inactivo
                    v-chip( v-if="item.active_audience == 1 && item.frequency != '-'" x-small color="green" dark) Activo

                template( v-slot:item.tasks="{ item }" )
                    v-btn(x-small @click="()=>openDialog('tasks', item)") Mostrar tareas

                template( v-slot:item.query="{ item }" ) 
                    v-btn(x-small @click="()=>openDialog('query', item)") Mostrar consulta
                

        v-dialog(
            v-model="dialog"
            persistent
            max-width="500px")
            v-card 
                v-card-title( class="headline" ) {{ dialog_title }}
                v-card-text
                    v-container
                        v-row
                            v-col(cols="12" v-if="this.dialog_text")
                                | {{this.dialog_text}}
                            v-col(cols="12" v-if="tasks.length")
                                v-simple-table
                                    template(v-slot:default)
                                        thead
                                            tr
                                                th(class="text-left") Estatus
                                                th(class="text-left") Fecha
                                        tbody
                                            tr(
                                            v-for="item in tasks"
                                            :key="item.id")
                                                td 
                                                    v-chip( v-if="item.status == 0" x-small color="yellow" ) Petición enviada.
                                                    v-chip( v-else x-small color="green" text-color="white" ) Audiencia creada.
                                                td {{ item.created_at }}
                v-card-actions
                    v-spacer
                    v-btn(
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                    ) Cerrar
            
		
			
</template>
<script>
	
    import LogsService from '../services/LogsService'

    const logsService = new LogsService();

	export default {
		name: 'FormsAudiencesTableLogs',
		data () {
			return {
                headers: [
                    {
                        text: 'Solicitante',
                        align: 'start',
                        sortable: false,
                        value: 'form_data.name',
                    },
                    {
                        text: 'Área',
                        align: 'start',
                        sortable: false,
                        value: 'form_data.area',
                    },
                    {
                        text: 'Objetivo',
                        align: 'start',
                        sortable: false,
                        value: 'form_data.target',
                    },
                    {
                        text: 'Canales',
                        align: 'start',
                        sortable: false,
                        value: 'channels',
                    },
                    {
                        text: 'Campaña',
                        align: 'start',
                        sortable: false,
                        value: 'form_data.campaign',
                    },
                    {
                        text: 'Fecha de la campaña',
                        align: 'start',
                        sortable: false,
                        width: "190",
                        value: 'campaign_date',
                    },




                    {
                        text: 'Audiencia',
                        align: 'start',
                        sortable: false,
                        width: 150,
                        value: 'name',
                    },
                    {
                        text: 'Plataforma',
                        align: 'start',
                        sortable: false,
                        value: 'platform',
                    },
                    {
                        text: 'Cuenta',
                        align: 'start',
                        sortable: false,
                        width: 130,
                        value: 'mcc_account',
                    },
                    {
                        text: 'Frecuencia audiencia',
                        align: 'start',
                        sortable: false,
                        value: 'frequency',
                    },
                    {
                        text: 'Tiempo automatización',
                        align: 'center',
                        sortable: false,
                        width: 190,
                        value: 'range_dates_audience',
                    },
                    {
                        text: 'Audiencia automatizada',
                        align: 'start',
                        sortable: false,
                        value: 'automate',
                    },
                    
                    {
                        text: 'Estatus tareas',
                        align: 'start',
                        sortable: false,
                        value: 'tasks',
                    },
                    {
                        text: 'SQL',
                        align: 'start',
                        sortable: false,
                        value: 'query',
                    },
                    {
                        text: 'Estatus Audiencia',
                        align: 'start',
                        sortable: false,
                        value: 'active_audience',
                    },
                    {
                        text: 'Fecha de la petición',
                        align: 'center',
                        sortable: false,
                        width: '140',
                        value: 'created_at',
                    },
                    
                ],
                data: [],
                search: '',
                loading: false,
                dialog_text: '',
                dialog_title: '',
                dialog: false,
                tasks: [], // estatus de las tareas de la audiencias
            }
		},
		components:{
		},
        computed: {
            items() {
                return this.data.map((item) => {
                    let channels = item.form_data.channels.split(',');
                    if(item.form_data.other_channel) {
                        channels.push(item.form_data.other_channel)
                    }
                    let campaign_date = item.form_data.start_date_campaign + ' - ' + item.form_data.end_date_campaign;
                    let range_dates_audience = item.start_date_automation !== '1000-01-01' ? item.start_date_automation + ' - ' + item.end_date_automation : '';
                    let automate = item.automate == 1 ? 'Si' : 'No'
                    let frequency = item.frequency_automation != '' ? item.frequency_automation == 'weekly' ? 'Semanal' : item.frequency_automation == 'monthly' ? 'Mensual' : 'Quincenal' : '-';
                    return {...item, channels, campaign_date, range_dates_audience, automate, frequency}
                })
            }
        },
		mounted() {
            this.init();
		},
		methods:{
			init() {
                this.fetchData();
            },

            fetchData() {
                this.loading = true;
                logsService.formAudiencesLogs()
                    .then(response => this.data = response.data.data)
                    .catch( reason => console.logs(reason.error) )
                    .then(() => this.loading = false);
            },

            openDialog(context, data) {
                this.dialog = true;
                if(context=='query'){
                    this.dialog_text = data.query;
                    this.dialog_title = 'Consulta SQL';
                    this.tasks = [];
                }

                if(context=='tasks'){
                    
                    this.dialog_text = '';
                    this.tasks = data.tasks;
                    this.dialog_title = 'Estatus de las tareas'

                }

            },

		}
	}
</script>