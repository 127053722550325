var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v("Audiencias"),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.channels",fn:function(ref){
var item = ref.item;
return _vm._l((item.channels),function(name){return _c('v-chip',{staticClass:"mr-1",attrs:{"x-small":"","dark":"","color":"secondary"}},[_vm._v(_vm._s(name))])})}},{key:"item.active_audience",fn:function(ref){
var item = ref.item;
return [(item.active_audience == 0 && item.frequency != '-')?_c('v-chip',{attrs:{"x-small":"","color":"red","dark":""}},[_vm._v("Inactivo")]):_vm._e(),(item.active_audience == 1 && item.frequency != '-')?_c('v-chip',{attrs:{"x-small":"","color":"green","dark":""}},[_vm._v("Activo")]):_vm._e()]}},{key:"item.tasks",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":""},on:{"click":function (){ return _vm.openDialog('tasks', item); }}},[_vm._v("Mostrar tareas")])]}},{key:"item.query",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":""},on:{"click":function (){ return _vm.openDialog('query', item); }}},[_vm._v("Mostrar consulta")])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialog_title))]),_c('v-card-text',[_c('v-container',[_c('v-row',[(this.dialog_text)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(_vm._s(this.dialog_text))]):_vm._e(),(_vm.tasks.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Estatus")]),_c('th',{staticClass:"text-left"},[_vm._v("Fecha")])])]),_c('tbody',_vm._l((_vm.tasks),function(item){return _c('tr',{key:item.id},[_c('td',[(item.status == 0)?_c('v-chip',{attrs:{"x-small":"","color":"yellow"}},[_vm._v("Petición enviada.")]):_c('v-chip',{attrs:{"x-small":"","color":"green","text-color":"white"}},[_vm._v("Audiencia creada.")])],1),_c('td',[_vm._v(_vm._s(item.created_at))])])}),0)]},proxy:true}],null,false,137984213)})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cerrar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }