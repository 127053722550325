<template  lang="pug">
	v-container(class="section-version")
		v-row(justify="center")
			v-col( cols="12" lg="10" )
				h1(class="display-1") Registro de los formularios
				br 
				v-tabs(v-model="tab" right)
					v-tab() Segmentador
					v-tab() Casos de Uso
					v-tab() Audiencias
				br
				v-tabs-items(v-model="tab")
					v-tab-item
						SegmenterTableLogs
					v-tab-item
						CasosDeUsoTableLogs
					v-tab-item
						FormsAudiencesTableLogs
</template>
<script>
	//import LogsService from '../../services/LogsService'
	//const logsService = new LogsService();

    import SegmenterTableLogs from '../../components/SegmenterTableLogs'
    import CasosDeUsoTableLogs from '../../components/CasosDeUsoTableLogs'
	import FormsAudiencesTableLogs from '../../components/FormsAudiencesTableLogs'
	

	export default {
		name: 'FormLogs',
		data() {
			return {
                tab: null
			}
		},
		components:{
			SegmenterTableLogs,
            CasosDeUsoTableLogs,
			FormsAudiencesTableLogs
		},
		mounted(){
		},
		methods:{	
		},
	}
</script>
